.input-custom.p-inputwrapper.p-treeselect {
  box-shadow: unset;
}

.p-treeselect-panel {
  .p-tree-selectable {
    padding-left: 0;
    padding-right: 0;
    .p-tree-container {
      .p-treenode {
        padding: 0;

        .p-treenode-content {
          padding-left: 12px;
          padding-right: 12px;
          border-radius: unset;

          &.p-treenode-selectable.p-highlight {
            background-color: #2684ff;
            color: white;
          }

          &:hover {
            background-color: #deebff;
          }
        }

        &:focus > .p-treenode-content {
          box-shadow: unset;
        }
      }
    }
  }
}
