.ps-submenu-root {
  .ps-submenu-expand-icon {
    display: none;
  }

  .ps-menu-suffix {
    transform: rotate(0);
    transition: all ease .3s;
  }

  &.ps-open {
    .ps-menu-button {
      &.ps-open {
        background-color: #175CD3 !important;
        border-radius: 16px;
        color: white;

        .ps-menu-icon {
          svg {
            path {
              stroke: white;
            }
          }
        }

        .ps-menu-suffix {
          transform: rotate(180deg);
          transition: all ease .3s;
        }
      }
    }
  }

  .ps-menuitem-root:not(.ps-submenu-root) {
    .ps-menu-button:not(.ps-open) {
      height: 40px;
    }
  }

  .active-item {
    pointer-events: none;

    .ps-menu-button {
      &:hover {
        .ps-menu-label {
          color: #D1E9FF !important;
        }
      }
    }
  }
}
