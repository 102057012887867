.p-radiobutton {
  width: 20px;
  height: 20px;

  .p-radiobutton-box {
    @apply border border-gray-300;
    width: 20px;
    height: 20px;
  }

  &.p-highlight {
    .p-radiobutton-box {
      @apply border-primary-600;
      @apply bg-primary-50;

      .p-radiobutton-icon {
        @apply bg-primary-600;
        height: 8px;
        width: 8px;
      }
    }
  }
}

.radio-input {
  @apply border border-gray-300;
  padding: 12px 14px;
  border-radius: 8px;
}
