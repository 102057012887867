.dialog-custom.p-dialog {
  .p-dialog-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 20px 16px;

    .p-dialog-title {
      @apply text-gray-700;
      font-size: 18px;
      font-weight: 500;
    }

    &-icon {
      svg {
        @apply text-gray-500;
        width: 16px;
        height: 16px;
      }
    }
  }

  .p-dialog-content {
    &.border-top {
      @apply border-t border-gray-200;
    }

    &.border-bottom {
      @apply border-b border-gray-200;
    }
  }

  .p-dialog-footer {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 20px 16px;
  }
}

.dialog-progress-mark {
  height: calc(100vh - 10px) !important;

  .dialog-progress {
    width: 1200px;

    .p-dialog-header {
      padding: 20px;
    }

    .p-dialog-content {
      @apply border-t border-gray-200;
      padding: 20px 20px 0;

      .p-tabview.p-component {
        .p-tabview-nav {
          .p-unselectable-text {
            @apply border-b border-primary-50;
            border-bottom-width: 2px !important;
            position: relative;
            bottom: -1px;
          }

          .p-tabview-selected.p-highlight {
            @apply border-b border-primary-700;
          }
        }

        .p-tabview-panels {
          padding: 0;
        }
      }

      .table-custom {
        .p-datatable {
          .p-datatable-table {
            border-collapse: separate;
          }

          .p-datatable-thead {
            th {
              @apply border-r border-gray-300;

              &:last-child {
                border-right: 0;
              }
            }
          }

          .p-datatable-tbody {
            tr {
              td {
                @apply border-r border-gray-300;
                @apply border-b border-gray-300;
                padding: 10px 14px;
                line-height: 24px;

                &:first-child {
                  border-left: unset;
                }

                &:last-child {
                  border-right: unset;
                }

                &:has(> .bg-pink) {
                  @apply bg-error-100;
                }
              }

              &:last-child {
                border-bottom: 0;

                td {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .p-dialog-footer {
      padding: 20px;

      .p-paginator {
        @apply border-b border-gray-200;
        padding: 0;
        padding-bottom: 20px;
      }
    }
  }
}

.dialog-preview-video {
  &.dialog-custom.p-dialog {
    .p-dialog-header {
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .p-dialog-title {
        word-break: break-all;
        max-width: 612px;
      }

      .p-dialog-header-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
    }

    .p-dialog-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.dialog-add-version,
.dialog-logout {
  &.dialog-custom.p-dialog {
    .p-dialog-content {
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .p-dialog-header {
      @apply border-b border-gray-200;
    }

    .p-dialog-footer {
      @apply border-t border-gray-200;
    }
  }
}

.dialog-create-group {
  .p-dialog-content {
    padding: unset;
  }

  .p-dialog-header {
    @apply border-b border-gray-200;
  }

  .p-dialog-footer {
    @apply border-t border-gray-200;
  }

  svg.size-large {
    path {
      stroke-width: 1;
    }
  }
}