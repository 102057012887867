.input-otp {
  &__wrapper {
    .p-inputotp {
      gap: 16px;

      .p-inputotp-input {
        @apply border border-gray-300;
        padding: 10px 14px;
        border-radius: 8px;
        height: 44px;
        // width: 46.67px;
        flex: 1;

        &:focus {
          box-shadow: unset;
        }
      }
    }
  }
}
