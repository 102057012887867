@use '../abstracts/variables';

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  @apply text-gray-500;
}
::-moz-placeholder { /* Firefox 19+ */
  @apply text-gray-500;
}
:-ms-input-placeholder { /* IE 10+ */
  @apply text-gray-500;
}
:-moz-placeholder { /* Firefox 18- */
  @apply text-gray-500;
}

// hide arrow on number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html {
  @apply text-base;
}

body {
  @apply bg-white text-gray-700;
  font-family: "Hiragino Sans", "ヒラギノ角ゴ", sans-serif;
  font-size: 1rem;
}

.p-component {
  font-family: "Hiragino Sans", "ヒラギノ角ゴ", sans-serif;
}

.break-word {
  word-break: break-word;
}

.ps-sidebar-root {
  border: none !important;
  padding-left: 16px;
  padding-right: 16px;

  .logo {
    svg {
      transition: all 0.3s;
    }
  }

  &.ps-collapsed {
    .ps-menu-button {
      padding-left: 5px;
    }

    .logo {
      svg {
        width: 45px;
      }
    }
  }

  .ps-menu-button {
    &:hover {
      @apply bg-transparent #{!important};
      border-radius: 16px;

      .ps-menu-label {
        @apply text-white;
      }
      .ps-menu-icon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.ps-active {
  @apply bg-primary-700;
  border-radius: 16px;
  pointer-events: none;

  .ps-menu-label {
    @apply text-white;
  }
  .ps-menu-icon {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.w-screen {
  border-top-left-radius: 40px;
}

.fileupload-custom {
  .p-message-error {
    @apply absolute left-0 right-0 top-0;
  }
  .p-progressbar {
    display: none;
  }
}

.date-custom {
  @apply flex;
  .p-datepicker-trigger {
    order: 1;
    width: auto;
    padding: 0 8px 0 0;
    &:focus {
      box-shadow: none;
    }
  }
  .p-inputtext {
    order: 2;
    box-shadow: none !important;
    &:focus {
      box-shadow: none;
    }
  }
}

.p-treeselect-label {
  padding-left: 0;
}
.p-treeselect-trigger {
  width: auto;
}

.table-custom {
  border-radius: 12px;
  overflow: hidden;
  @apply border border-gray-300;

  .p-paginator {
    padding: 24px 16px;
  }

  &.hide-border-bottom {
    .p-datatable-tbody {
      tr {
        &:last-child {
          border-bottom: unset;
        }
      }
    }
  }
}

.p-datatable-header,
.p-datatable-footer {
  border-radius: 12px 12px 0 0;
}

.p-datatable-wrapper {
  // Double check this property
  border-radius: 0 0 12px 12px;
}
.p-datatable-tbody {
  tr {
    @apply border-b border-gray-300;
    td {
      padding: 24px 16px;
    }
  }
}

.p-datatable-thead {
  tr {
    th {
      @apply border-b border-gray-300;
      padding: 12px 16px;
      .p-column-title {
        font-weight: normal;
        font-size: 16px;
      }

      &.text-center {
        .p-column-header-content {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.p-datatable-emptymessage {
  td {
    @apply text-center p-5;
  }
}

.p-inputswitch {
  &.p-highlight {
    .p-inputswitch-slider {@apply bg-primary-700;}
  }
}

.p-paginator {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-prev {
    margin-left: 0;
  }
  .p-paginator-element {
    &:focus {
      box-shadow: none;
    }
  }
}

.p-checkbox {
  &.p-highlight {
    .p-checkbox-box {
      @apply bg-primary-900;
      border-radius: 0;
    }
  }
}

.p-toast-message-error {
  @apply bg-error-100;
}

.p-toast-message-success {
  @apply bg-primary-100;
}

.checkbox-sm .p-checkbox {
  width: 16px;
  height: 16px;
}

.checkbox-sm .p-checkbox-box {
  width: 120%;
  height: 100%;
}

.checkbox-custom .p-checkbox input[type="checkbox"]:focus + .p-checkbox-box {
  outline: none;
  box-shadow: none;
}

.p-datepicker-title {
  .p-datepicker-year {
    &:after {
      content: '年';
    }
  }
}

.p-treeselect-empty-message {
  display: none;
}

.p-tabview-nav {
  @apply border-b border-gray-200;
}
.p-tabview .p-unselectable-text {
  @apply flex items-center;
  padding: 12px;
  .p-badge {
    @apply flex items-center justify-center bg-gray-100 text-gray-700;
    width: 25px;
    height: 22px;
  }
}

.p-tabview .p-tabview-selected {
  &.p-highlight {
    @apply bg-primary-50;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .tab-label {
      @apply text-primary-700;
    }

    .p-badge {
      @apply bg-primary-100 text-primary-700;
    }
  }
}

.p-tabview.p-component.highlight-bottom {
  .p-tabview-nav {
    .p-unselectable-text {
      @apply border-b border-primary-50;
      border-bottom-width: 2px !important;
      position: relative;
      bottom: -1px;
    }

    .p-tabview-selected.p-highlight {
      @apply border-b border-primary-700;
    }
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.p-tree-selectable {
  padding: 5px 10px;
  .p-tree-toggler {
    display: none !important;
  }
}

.p-datepicker {
  padding: 5px 0;

  &.calendar-date-of-birth {
    width: 300px !important;
    max-width: 300px !important;
    min-width: auto !important;
  }

  .p-datepicker-header {
    padding: 4px;
  }
  .p-datepicker-calendar {
    margin: 0;

    tbody {
      tr {
        td {
          padding: 5px 2px;
        }
      }
    }
  }
}

.p-calendar {
  .p-inputtext {
    box-shadow: none !important;
  }
}
.p-datepicker-trigger {
  &.p-button {
    box-shadow: none !important;
  }
}

.tree-hide-header {
  .p-treeselect-header {
    display: none;
  }
}

.hide-footer-dialog {
  .p-dialog-footer {
    .p-button {
      display: none;
    }
  }
}

.tree-disabled-unselect {
  .p-treenode {
    .p-treenode-content {
      &.p-highlight {
        pointer-events: none;
      }
    }
  }
}

.pi-drag-drop {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('../../images/dots-grid.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.questions-tab-nav {
  margin-top: 20px;

  .p-tabview-nav {
    @apply border-primary-700;
    max-width: 708px;
    border-width: 2px;
    border-top: unset;
    border-left: unset;
    border-right: unset;

    .p-tabview-selected {
      padding: 12px;
      width: 100%;

      .tab-label {
        font-weight: 500;
      }

      .input {
        gap: 16px;
      }

      .radio-input {
        border: unset;
        padding: unset;
        height: fit-content;

        label {
          text-wrap: nowrap;
        }
      }
    }
  }
}

.bubble-right::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  @apply border-l-[10px] border-solid border-l-blue-25;
}

.bubble-left::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  @apply border-r-[10px] border-solid border-r-gray-300;
}

svg.stroke-width-2 {
  path {
    stroke-width: 2;
  }
} 

.disabled-container {
  background-color: #f2f2f2;
  pointer-events: none;
  user-select: none;
  opacity: 0.6;

  &.upload-file-custom {
    .p-fileupload-content {
      background-color: #f2f2f2;
    }
  }
}
