.upload-video-custom {
  .p-fileupload-content {
    padding: 16px 22px;
  }

  .p-progressbar {
    display: none;
  }
}

.upload-file-custom {
  .p-fileupload-content {
    @apply bg-white;
    padding: 14px 12px;
    height: 44px;
    display: flex;
    align-items: center;

    div:has(> .p-fileupload-row) {
      width: 100%;
    }

    .pi {
      @apply text-gray-500;
    }
  }

  .p-progressbar {
    display: none;
  }

  .p-fileupload-row {
    > div {
      padding: 0;
    }
  }
}

.avatar-upload-container {
  .p-fileupload-content {
    display: none;
  }

  position: absolute;
  bottom: 0;
  right: 0;
}
