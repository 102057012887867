// The directory structure is referenced from: https://www.easeout.co/blog/2020-08-25-structuring-your-sass-projects/

// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Vendors

// Base
@import 'bases/global';

//Components
@import 'components/dialog-custom';
@import 'components/radio-button-custom';
@import 'components/upload';
@import 'components/input-otp';
@import 'components/left-menu';
@import 'components/checkbox-custom';
@import 'components/tree-select';

@layer utilities {
  .flex-full-center {
    @apply flex items-center justify-center;
  }

  .infinity-select .select__control,
  .input-custom {
    @apply duration-100 ease-in border border-gray-300 w-full;
    border-radius: 8px;
    padding: 10px 14px;
    &:focus-visible {
      box-shadow: none;
      outline: none;
    }
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }

  .infinity-select {
    outline: none !important;
    .select {
      &__control {
        padding: 2.5px 14px;
        outline: none !important;
        box-shadow: none !important;
        border-color: #d0d5dd;

        &--menu-is-open {
          &:hover {
            border-color: #d0d5dd;
          }
        }

        &:hover {
          border-color: #d0d5dd;
        }
      }

      &__value-container {
        padding: 0;
      }
    }

    &.select--is-disabled {
      opacity: 0.7;
    }
  }

  .label-custom {
    @apply text-gray-900 text-lg mb-2;
  }
}
// Layouts

// Components
@layer components {
  .header-top {
    @apply border-b border-gray-200;
    padding-top: 15px;
    padding-bottom: 25px;
    .title {
      font-size: 30px;
    }
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  .disabled-custom {
    @apply opacity-5 pointer-events-none;
  }
}
// Pages

* {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: gray;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 14px;
  border: 3px solid transparent;
}
